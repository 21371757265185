import React, { FunctionComponent, useEffect, useState } from "react";
import PublicConnection from "../../utils/connections/public";
import { useParams } from "react-router-dom";
import Spinner from "../../global/atoms/Spinner/Spinner";
import { IReviewPhotoSimple, IReviewVisit } from "../../utils/models";
import styles from "../visitReviews/components/styles.module.scss";
import SeparatorLine from "../../global/atoms/separators/SeparatorLine";
import "lightbox2/dist/js/lightbox-plus-jquery.min";
import "lightbox2/dist/css/lightbox.min.css";
import Instances from "../../utils/connections/instances";

interface IVisitPhotos {}

interface IPhoto {
  photoSimple: Array<IReviewPhotoSimple>;
  visit: IReviewVisit;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const VisitPhotos: FunctionComponent<IVisitPhotos> = (props) => {
  const { organizationId, projectId, visitId, questionId } = useParams<{
    organizationId: string;
    projectId: string;
    visitId: string;
    questionId: string;
  }>();

  const [photos, setPhotos] = useState<null | Array<IPhoto>>(null);

  useEffect(() => {
    PublicConnection.getQuestionPhotos(
      organizationId,
      projectId,
      visitId,
      questionId
    ).then((res) => {
      setPhotos(res.data.data);
    });
  }, []);

  if (photos === null) return <Spinner />;

  const getPhotos = (): Array<IReviewPhotoSimple> => {
    const resArr: Array<IReviewPhotoSimple> = [];

    photos.forEach((p: IPhoto) => {
      p.photoSimple.forEach((ps: IReviewPhotoSimple) => {
        resArr.push(ps);
      });
    });

    return resArr;
  };

  return (
    <>
      <div>
        {photos && photos[0] && (
          <>
            Projekt: <strong>{photos[0].visit.projectName}</strong>
            <br />
            Lokalizacja: <strong>{photos[0].visit.locationName}</strong>
            <br />
            Raportujący: <strong>{photos[0].visit.reportingUserName}</strong>
            <br />
            Zadanie: <strong>{photos[0].photoSimple[0].taskName}</strong>
            <br />
            Aktywność: <strong>{photos[0].photoSimple[0].activityName}</strong>
            <br />
            Pytanie: <strong>{photos[0].photoSimple[0].questionName}</strong>
            <br />
            Data: <strong>{photos[0].visit.startDate}</strong>
            <br />
          </>
        )}
      </div>

      <SeparatorLine size={2} />

      <div style={{ overflowX: "auto", overflowY: "hidden" }}>
        <div style={{ display: "flex" }}>
          {getPhotos().map((p) => {
            return (
              <>
                <div className={`${styles.photoReviewThumbWrapper}`}>
                  <a
                    className={styles.innerLink}
                    href={Instances.getQuestionImageLink(
                      p.value.refQuestionAnswerId
                    )}
                    data-lightbox={`question-gallery`}
                    data-title={p.questionName}>
                    <div
                      className={styles.photoReviewImageWrapper}
                      style={{ width: "300px" }}>
                      <img src={p.photoContentThumbUrl ?? ""} alt={""} />
                    </div>
                  </a>
                </div>
              </>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default VisitPhotos;
