import React, {FunctionComponent, useEffect, useState} from "react";
import Modal from "rsuite/Modal";
import {IModalData} from "../Schedule";
import {ISelect, SetState} from "../../../utils/models";
import dayjs from "dayjs";
import isoWeek from "dayjs/plugin/isoWeek";
import Spinner from "../../../global/atoms/Spinner/Spinner";
import Button from "rsuite/Button";
import {MONTHS, WEEK_DAYS} from "../../../utils/dictionaries";
import {DatePicker, SelectPicker} from "rsuite";
import SchedulesConnection from "../../../utils/connections/schedules";
import {handleToast} from "../../../utils/helpers";
import styles from "../../../global/atoms/atoms.module.scss";
import {IScheduleModalData} from "./ScheduleModal";

dayjs.extend(isoWeek);

interface IScheduleModalMove {
    modalData: IModalData | null;
    setModalData: SetState<IModalData | null>;
    reload: any;
}

interface IScheduleModalFormData {
    location: ISelect;
    task: ISelect;
    user: ISelect;
    date: Date;
    allowedDates: {
        'data': string[]
    } | null;
}

const ScheduleMoveModal: FunctionComponent<IScheduleModalMove> = (props) => {
    const [formData, setFormData] = useState<IScheduleModalFormData | null>(null);
    const [errors, setErrors] = useState<any | null>(null);
    const [dateTo, setDateTo] = useState<Date | null>(null);

    const mdate = dayjs(props.modalData?.date ?? new Date());
    const dayNumber = mdate.date().toString();
    const monthName = MONTHS[mdate.month()];
    const dayName = WEEK_DAYS[mdate.isoWeekday()];

    useEffect(() => {
        if (props.modalData?.timeTableId) {
            SchedulesConnection.moveModalData(
                props.modalData.timeTableId,
                mdate
            ).then((response) => {
                setFormData(response.data);
            });
        }
    }, [props.modalData]);

    const onClose = () => {
        props.setModalData(null);
        setDateTo(null);
        setErrors(null);
    };

    const onSubmit = () => {
        setErrors(null);
        handleToast(SchedulesConnection.move({
            "id": props.modalData?.timeTableId,
            "dateFrom": props.modalData?.date ? dayjs(props.modalData.date).unix() : null,
            "dateTo": dateTo ? dayjs(dateTo).unix() : null,
            "option": 1,
        }), setErrors).then(() => {
            onClose();
            props.reload();
        });
    }

    return (
        <>
            <Modal open={props.modalData?.type === "move"} onClose={onClose}>
                <Modal.Header>
                    <h4>
                        Przeniesienie wizyty - {dayNumber} {monthName}, {dayName}
                    </h4>
                </Modal.Header>
                <Modal.Body>
                    {!formData ? (<Spinner/>) : (
                        <>
                            <br/>
                            <div>
                                <strong>Lokalizacja:</strong>
                                <SelectPicker
                                    data={formData.location.options}
                                    value={formData.location.value}
                                    valueKey={"id"}
                                    labelKey={"name"}
                                    block
                                    readOnly={formData.location.disabled}
                                />
                            </div>
                            <br/>
                            <div>
                                <strong>Zadanie:</strong>
                                <SelectPicker
                                    data={formData.task.options}
                                    value={formData.task.value}
                                    valueKey={"id"}
                                    labelKey={"name"}
                                    block
                                    readOnly={formData.task.disabled}
                                />
                            </div>
                            <br/>
                            <div>
                                <strong>Raportujący:</strong>
                                <SelectPicker
                                    data={formData.user.options}
                                    value={formData.user.value}
                                    valueKey={"id"}
                                    labelKey={"name"}
                                    block
                                    readOnly={formData.user.disabled}
                                />
                            </div>
                            <br/>
                            <div>
                                <strong>Przeniesienie wizyty na dzień:</strong>
                                <DatePicker
                                    format={"yyyy-MM-dd"}
                                    value={formData.date}
                                    isoWeek
                                    onChange={(value) => setDateTo(value)}
                                    shouldDisableDate={(date) =>
                                        date.toISOString() <= (new Date).toISOString()
                                        // formData?.allowedDates?.data !== null && !formData?.allowedDates?.data.includes((
                                        //     date.getFullYear() + '-' + (date.getUTCMonth() + 1) + "-" + date.getUTCDate()
                                       // ))
                                    }
                                    block
                                />
                                {errors && (
                                    <span className={styles.errors}>{errors.date}</span>
                                )}
                            </div>
                        </>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        appearance={"primary"}
                        disabled={dateTo === null}
                        onClick={() => onSubmit()}>
                        Przenieś
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default ScheduleMoveModal;