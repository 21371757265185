import React, { FunctionComponent, useState } from "react";
import { IGetNotifications, INotifications } from "../../../../utils/models";
import { getNotificationsState } from "../../../../utils/states";
import NotificationsConnection from "../../../../utils/connections/notifications";
import HeaderButtons from "../../../../global/atoms/headerButtons/HeaderButtons";
import Table from "rsuite/Table";
import Pagination from "../../../../global/pagination/Pagination";
import styles from "../usersList/styles.module.scss";
import { useHistory } from "react-router-dom";

import UniversalFilters, {
  FILTER_INPUT_TEXT,
  FILTER_SELECT,
} from "../../../../global/filters/UniversalFilters";
import NavOptionsWrapper from "../../../../utils/NavOptionsWrapper";

import PaginatedTableViewWrapper from "global/PaginatedTableViewWrapper";
import { Button, Col, Dropdown, Input, Uploader } from "rsuite";
import Modal from "rsuite/Modal";
import PlusIcon from "@rsuite/icons/Plus";
import ActionsContainer from "../../../../global/atoms/ActionsContainer";
import FormControlLabel from "rsuite/FormControlLabel";
import { FileType } from "rsuite/esm/Uploader/Uploader";
import { handleSortColumn, handleToast } from "../../../../utils/helpers";
import FormErrorMessage from "rsuite/FormErrorMessage";
import SeparatorEmpty from "../../../../global/atoms/separators/SeparatorEmpty";
import { validate } from "uuid";
import ButtonListRedirect from "../../../../global/atoms/ButtonListRedirect";
import FileDownloadIcon from "@rsuite/icons/FileDownload";
import download from "downloadjs";
import EditIcon from "@rsuite/icons/Edit";
import VisibleIcon from "@rsuite/icons/Visible";
interface INotificationList {}

interface UniversalProperty {
  id: string;
  name: string;
  status: string;
  color: string;
  isDefault: boolean;
}

const NotificationList: FunctionComponent<INotificationList> = () => {
  const [data, setData] = useState<INotifications | null>(null);
  const [requestData, setRequestData] = useState<IGetNotifications>(
    getNotificationsState
  );
  const [filtersForm, setFiltersForm] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [addFileModalOpen, setAddFileModalOpen] = useState(false);
  const [fileList, setFileList] = useState<FileType[]>([]);
  const [fileData, setFileData] = useState<any>();
  const [errors, setErrors] = useState<any>();
  const [title, setTitle] = useState<string>("");
  const history = useHistory();

  const handleSendReport = (notificationId) => {
    NotificationsConnection.sendReportNotification(notificationId).then(
      (resp) => {
        console.log(resp);
        if (resp.status == 200) {
          download(resp.data, resp.headers["x-filename"], resp.data.type);
        }
      }
    );
  };
  const handleFileSave = () => {
    handleToast(
      NotificationsConnection.uploadFileNotification({
        title: title,
        ...fileData,
      }),
      setErrors
    ).then((resp) => {
      const notificationId = resp.data.notificationId;
      if (validate(notificationId)) {
        history.push(`/admin/notifications/manual/${notificationId}/edit`);
      }
    });
  };
  const handleFileAdd = (fileList: FileType[]) => {
    const file = fileList.pop();
    const uf: File | undefined = file?.blobFile;
    if (file !== undefined && uf !== undefined) {
      const reader = new FileReader();
      reader.readAsDataURL(uf);
      reader.onload = function () {
        setFileData((d) => ({
          ...d,
          fileMimeType: uf.type,
          fileName: uf.name,
          fileBase64: reader.result?.toString(),
        }));
      };
      reader.onerror = function (error) {
        console.log("Error: ", error);
      };
      setFileList([file]);
    }
  };

  const handleModalClose = () => {
    setAddFileModalOpen(false);
    setFileList([]);
    setTitle("");
    setFileData(null);
    setErrors(null);
  };

  const UniversalPropRender = (matchValue: string, options: any) => {
    let obj: UniversalProperty = {
      id: "",
      name: "",
      status: "",
      color: "",
      isDefault: true,
    };
    options.some((val: UniversalProperty) => {
      const res = val.id === matchValue;
      if (res) obj = val;
      return res;
    });

    return (
      <span
        className={styles.status}
        style={{ color: obj.color, borderColor: obj.color }}>
        {obj.name}
      </span>
    );
  };

  return (
    <>
      <HeaderButtons>
        <NavOptionsWrapper addPath={"/admin/notifications/manual/add"}>
          <Dropdown.Item
            icon={<PlusIcon />}
            onClick={() => {
              setAddFileModalOpen(true);
            }}>
            Import z pliku excel *.xlsx
          </Dropdown.Item>
        </NavOptionsWrapper>
      </HeaderButtons>

      <PaginatedTableViewWrapper
        decreaseContainerSizePx={40}
        table={
          <Table
            fillHeight
            data={data?.data ?? []}
            loading={loading}
            sortColumn={requestData.requestOrder.field}
            sortType={requestData.requestOrder.order}
            onSortColumn={(dataKey, sortType) =>
              handleSortColumn(dataKey, sortType, setRequestData)
            }>
            <Table.Column flexGrow={1} align="left" sortable>
              <Table.HeaderCell>Temat</Table.HeaderCell>
              <Table.Cell dataKey="subject">
                {(rowData) => (
                  <>
                    <div style={{ position: "relative" }}></div>
                    <ButtonListRedirect
                      redirectUrl={`/admin/notifications/manual/${rowData.id}/${
                        rowData.status != "PREPARE" ? "view" : "edit"
                      }`}>
                      {rowData.subject}
                    </ButtonListRedirect>
                    <div
                      style={{
                        position: "absolute",
                        display: "block",
                        color: "rgba(134,134,134,0.48)",
                        fontSize: "0.7em",
                        marginTop: "5px",
                        marginBottom: "5px",
                      }}>
                      Utw: {rowData.createAt} {rowData.createFrom} przez:{" "}
                      {rowData.createdByName}
                    </div>
                  </>
                )}
              </Table.Cell>
            </Table.Column>

            <Table.Column width={120} sortable>
              <Table.HeaderCell>Start</Table.HeaderCell>
              <Table.Cell dataKey="scheduleDate" />
            </Table.Column>

            <Table.Column width={120} sortable>
              <Table.HeaderCell>Koniec</Table.HeaderCell>
              <Table.Cell dataKey="endDate" />
            </Table.Column>

            <Table.Column width={100} align="center" sortable>
              <Table.HeaderCell>źródło</Table.HeaderCell>
              <Table.Cell width={1} depth={0}>
                {(rowData) => {
                  return UniversalPropRender(
                    rowData.sourceType,
                    filtersForm?.sourceTypes.options ?? []
                  );
                }}
              </Table.Cell>
            </Table.Column>

            <Table.Column width={120} align="center" sortable>
              <Table.HeaderCell>Kanał</Table.HeaderCell>
              <Table.Cell dataKey="sendChannel">
                {(rowData) => {
                  return UniversalPropRender(
                    rowData.sendChannel,
                    filtersForm?.sendChannels.options ?? []
                  );
                }}
              </Table.Cell>
            </Table.Column>

            <Table.Column width={155} sortable>
              <Table.HeaderCell>status</Table.HeaderCell>
              <Table.Cell dataKey="status">
                {(rowData) => {
                  return UniversalPropRender(
                    rowData.status,
                    filtersForm?.statuses.options ?? []
                  );
                }}
              </Table.Cell>
            </Table.Column>

            <Table.Column width={100}>
              <Table.HeaderCell>Akcje</Table.HeaderCell>
              <Table.Cell>
                {(rowData) => {
                  return (
                    <div
                      style={{
                        display: "flex",
                        gap: "5px",
                        justifyContent: "center",
                      }}>
                      {rowData.status == "PREPARE" && (
                        <EditIcon
                          style={{
                            cursor: "pointer",
                            width: "15px",
                            height: "15px",
                            color: "#E09616",
                          }}
                          onClick={() => {
                            history.push(
                              `/admin/notifications/manual/${rowData.id}/edit`
                            );
                          }}
                        />
                      )}
                      {rowData.status == "SENT" && (
                        <VisibleIcon
                          style={{
                            cursor: "pointer",
                            width: "15px",
                            height: "15px",
                            color: "#E09616",
                          }}
                          onClick={() => {
                            history.push(
                              `/admin/notifications/manual/${rowData.id}/view`
                            );
                          }}
                        />
                      )}

                      <FileDownloadIcon
                        style={{
                          cursor: "pointer",
                          width: "15px",
                          height: "15px",
                          color: "#E09616",
                        }}
                        onClick={handleSendReport.bind(null, rowData.id)}
                      />
                    </div>
                  );
                }}
              </Table.Cell>
            </Table.Column>
          </Table>
        }
        pagination={
          <Pagination
            count={data?.count ?? 0}
            page={requestData.requestPaginate.page.toString()}
            limit={requestData.requestPaginate.limit.toString()}
            setState={setRequestData}
          />
        }
      />

      <Modal
        style={{ marginTop: "100px" }}
        open={addFileModalOpen}
        size={"sm"}
        onClose={handleModalClose}>
        <Modal.Header>Import z pliku excel *.xlsx</Modal.Header>
        <Modal.Body>
          <Col xs={24} style={{ paddingLeft: "0px" }}>
            <FormControlLabel>Tytuł wiadomości</FormControlLabel>
            <Input
              name={"setTitle"}
              value={title}
              onChange={(v) => setTitle(v)}
            />
            <FormErrorMessage placement={"bottomEnd"} show={errors?.title}>
              {errors?.title}
            </FormErrorMessage>
          </Col>
          <Col xs={24} style={{ marginTop: "10px", paddingLeft: "0px" }}>
            <FormControlLabel>Plik importu .xlsx</FormControlLabel>
            <Uploader
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              fileList={fileList}
              name={"file"}
              autoUpload={false}
              multiple={false}
              onChange={handleFileAdd}
              onRemove={() => {
                setFileList([]);
                setFileData(null);
              }}
              action={""}>
              <Button appearance={"ghost"}>Wybierz plik</Button>
            </Uploader>
            <FormErrorMessage placement={"bottomEnd"} show={errors?.fileName}>
              {errors?.fileName}
            </FormErrorMessage>
          </Col>
          <SeparatorEmpty />
        </Modal.Body>
        <Modal.Footer>
          <ActionsContainer>
            <Button appearance="ghost" onClick={handleModalClose}>
              Anuluj
            </Button>
            <Button appearance="primary" onClick={handleFileSave}>
              Zapisz zmiany
            </Button>
          </ActionsContainer>
        </Modal.Footer>
      </Modal>

      <UniversalFilters
        load={NotificationsConnection.getNotifications}
        state={requestData}
        setState={setRequestData}
        setLoading={setLoading}
        setResultData={setData}
        defaultStateData={getNotificationsState}
        formGet={NotificationsConnection.getFilterForm}
        getFormCallback={(formData) => setFiltersForm(formData)}
        filterStorageKey={"notificationList"}
        elements={[
          {
            type: FILTER_INPUT_TEXT,
            label: "Temat",
            stateKey: "subject",
          },
          {
            type: FILTER_SELECT,
            label: "Kanał dystrybucji",
            stateKey: "sendChannel",
            formKey: "sendChannels",
          },
          {
            type: FILTER_SELECT,
            label: "Źródło",
            stateKey: "sourceType",
            formKey: "sourceTypes",
          },
          {
            type: FILTER_SELECT,
            label: "Status",
            stateKey: "status",
            formKey: "statuses",
          },
        ]}
      />
    </>
  );
};

export default NotificationList;
