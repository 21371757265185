import React, { memo } from "react";
import { FCC, IVisitPosition } from "../../../../../../utils/models";
import { PanelGroup } from "rsuite";
import VisitProductPanel from "./VisitProductPanel";
import VisitQuestionPanel from "./VisitQuestionPanel";

const VisitQuestionItemsPanelGroup: FCC<{ visitPosition: IVisitPosition }> = (
  props
) => {
  const positions = props.visitPosition.positions;

  const hasChildrenPos = (q: IVisitPosition): boolean => {
    return q.positions && q.positions.length > 0;
  };

  const hasNotChildrenPos = (q: IVisitPosition): boolean => {
    return !hasChildrenPos(q);
  };

  return (
    <PanelGroup bordered defaultActiveKey={0} style={{ background: "#fff" }}>
      {positions
        .filter(hasChildrenPos)
        .map((q: IVisitPosition, index: number) => (
          <VisitProductPanel
            key={`product-panel-${index}`}
            item={q}
            index={index}
          />
        ))}
      {positions
        .filter(hasNotChildrenPos)
        .map((q: IVisitPosition, index: number) => (
          <VisitQuestionPanel
            key={`question-panel-${index}`}
            visitPosition={props.visitPosition}
            question={q.question}
            index={index}
          />
        ))}
    </PanelGroup>
  );
};

export default memo(VisitQuestionItemsPanelGroup);
