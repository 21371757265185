import AxiosHelper from "../AxiosHelper";
import { getBaseApiUrl, getOrganizationId } from "../helpers";

const baseUrl = `${getBaseApiUrl()}/organizations/${getOrganizationId()}/dashboard`;

export interface IDashboardPoint {
  id: string;
  isDefault: boolean | null;
  taskId: string;
  userId: string;
  locationId: string;
  projectId: string;
  locationAddress: string;
  networkId: string;
  lat: number | null;
  lon: number | null;
}

const DashboardConnection = {
  getPoints: (data) => {
    return AxiosHelper.post(
      `${baseUrl}/visits/list`,
      data,
      undefined,
      "dashboard_points"
    );
  },

  getFilters: (data) => {
    return AxiosHelper.post(
      `${baseUrl}/filter/form`,
      data,
      undefined,
      "dashboard_filter"
    );
  },
};

export default DashboardConnection;
