import { AxiosManager, HttpMethod, getApiUrl } from "./../AxiosHelper";
import AxiosHelper, { default as axios } from "../AxiosHelper";
import { getAuthHeader, getBaseApiUrl, getOrganizationId } from "../helpers";
import { ICreateRole, IGetRoles, IPermission } from "../models";

const RolesConnection = {
  getRoles: (data: IGetRoles) => {
    return axios.post(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/roles/list`,
      data,
      {
        headers: getAuthHeader(),
      }
    );
  },
  getFormFilters: () => {
    return axios.get(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/roles/filter/form`,
      {
        headers: getAuthHeader(),
      }
    );
  },
  getFormAddRole: () => {
    return axios.get(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/roles/form`,
      {
        headers: getAuthHeader(),
      }
    );
  },
  getFormEditRole: (roleId: string) => {
    return axios.get(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/roles/${roleId}/form`,
      {
        headers: getAuthHeader(),
      }
    );
  },
  createRole: (data: ICreateRole) => {
    return axios.post(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/users/roles`,
      data,
      {
        headers: getAuthHeader(),
      }
    );
  },
  editRole: (roleId: string, data: ICreateRole) => {
    return AxiosHelper.put(getApiUrl(`users/roles/${roleId}`), data);
  },
  getFilterForm: () => {
    return axios.get(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/roles/filter/form`
    );
  },
  getPermissions: (roleId: string, projectId: string | undefined) => {
    interface IBody {
      active: Array<{ roleId: string; id: string; permissionId: string }>;
      permissions: Array<IPermission>;
    }
    let uri = `${getBaseApiUrl()}/organizations/${getOrganizationId()}/role_permission`;
    if (projectId) {
      uri += `/projects/${projectId}/${roleId}`;
    } else uri += `/${roleId}`;
    return AxiosManager.send<IBody>(HttpMethod.get, uri);
  },
  setPermissions: (
    roleId: string,
    projectId: string | undefined,
    permissionIds: Array<string>
  ) => {
    let uri = `${getBaseApiUrl()}/organizations/${getOrganizationId()}/role_permission`;
    if (projectId) {
      uri += `/projects/${projectId}/${roleId}`;
    } else uri += `/${roleId}`;
    return AxiosManager.send(HttpMethod.post, uri, permissionIds);
  },
  // rolesEdit: (data: ICreateRole) => {
  //   return axios.post(`${getBaseApiUrl()}/organizations/${getOrganizationId()}/components/roles`, data, {
  //     headers: getAuthHeader(),
  //   });
  // },
};

export default RolesConnection;
