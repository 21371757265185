import React, { FunctionComponent, useState } from "react";
import UniversalFilters from "../../../../../global/filters/UniversalFilters";
import AlertsConnection from "../../../../../utils/connections/alertsConnection";
import Pagination from "../../../../../global/pagination/Pagination";
import { IAlertEntity, IGetAlertList } from "../../../../../utils/models";
import NavOptionsWrapper from "../../../../../utils/NavOptionsWrapper";
import HeaderButtons from "../../../../../global/atoms/headerButtons/HeaderButtons";
import { Table } from "rsuite";
import { generatePath, useHistory } from "react-router-dom";
import {
  getProjectIdFromUrl,
  handleSortColumn,
  handleToastRedux,
} from "../../../../../utils/helpers";
import IconButton from "../../../../../global/atoms/IconButton";
import ButtonListRedirect from "../../../../../global/atoms/ButtonListRedirect";
import styles from "./styles.module.scss";
import { confirmModalCallback } from "redux/store";
import { useDispatch } from "react-redux";
import GlobalIcon from "@rsuite/icons/Global";
import { _paginate30 } from "utils/states";
import PaginatedTableViewWrapper from "global/PaginatedTableViewWrapper";
import { RowDataType } from "rsuite/esm/Table";

interface IAlerts {
  decreaseContainerSizePx?: number;
}

const Alerts: FunctionComponent<IAlerts> = (props) => {
  const [triggerLoad, setTriggerLoad] = useState(0);
  const [loading, setLoading] = useState<boolean>(true);
  const defaultState: IGetAlertList = {
    name: "",
    ..._paginate30,
  };

  const dispatch = useDispatch();
  const history = useHistory();
  const [state, setState] = useState<IGetAlertList>(defaultState);
  const [data, setData] = useState<{
    count: number;
    data: IAlertEntity[];
    roles: Array<{ id: string; name: string }>;
    activities: Array<{ id: string; name: string }>;
  }>({
    count: 0,
    data: [],
    roles: [],
    activities: [],
  });

  const getEditPath = (item: IAlertEntity) => {
    if (getProjectIdFromUrl()) {
      return generatePath("/projects/:id/edit/alerts/:alertId/edit", {
        alertId: item.id ?? "",
        id: getProjectIdFromUrl() ?? "",
      });
    } else {
      return generatePath("/admin/notifications/auto/:alertId/edit", {
        alertId: item.id ?? "",
      });
    }
  };

  const getAddPath = (): string => {
    if (getProjectIdFromUrl()) {
      return generatePath("/projects/:id/edit/alerts/add", {
        id: getProjectIdFromUrl() ?? "",
      });
    } else {
      return "/admin/notifications/auto/add";
    }
  };

  const handleChangeToEdit = (item: IAlertEntity) => {
    history.push(getEditPath(item));
  };

  const onDelete = (alertId: string, alertName: string) => {
    confirmModalCallback(
      <>
        <h1>{"Czy na pewno chcesz usunąć to powiadomienie?"}</h1>
        {alertName}
      </>,
      () => {
        handleToastRedux(AlertsConnection.delete(alertId), dispatch).then(
          () => {
            setTriggerLoad(Date.now());
          }
        );
      }
    );
  };

  return (
    <>
      <HeaderButtons>
        <NavOptionsWrapper addPath={getAddPath()} />
      </HeaderButtons>

      <PaginatedTableViewWrapper
        decreaseContainerSizePx={props.decreaseContainerSizePx}
        table={
          <Table
            fillHeight
            headerHeight={60}
            loading={loading}
            data={data.data ?? []}
            sortColumn={state.requestOrder.field}
            sortType={state.requestOrder.order}
            onSortColumn={(dataKey, sortType) => {
              handleSortColumn(dataKey, sortType, setState);
            }}>
            {!getProjectIdFromUrl() && (
              <Table.Column
                align="left"
                flexGrow={1}
                minWidth={200}
                sortable
                resizable>
                <Table.HeaderCell>Projekty</Table.HeaderCell>
                <Table.Cell dataKey={"projectNames"}>
                  {(rowData) => (
                    <div style={{ width: "100%" }} title={rowData.projectNames}>
                      {rowData.projectNames}
                    </div>
                  )}
                </Table.Cell>
              </Table.Column>
            )}
            <Table.Column
              align="left"
              flexGrow={2}
              minWidth={300}
              sortable
              resizable>
              <Table.HeaderCell>
                Nazwa
                <div className={styles.subHeader}>utworzono</div>
              </Table.HeaderCell>
              <Table.Cell dataKey={"name"}>
                {(rowData) => (
                  <>
                    <ButtonListRedirect
                      redirectUrl={getEditPath(rowData as IAlertEntity)}>
                      {getProjectIdFromUrl() &&
                        rowData.projectIds?.length > 1 && (
                          <>
                            <GlobalIcon />
                            &nbsp;
                          </>
                        )}
                      <div style={{ width: "100%" }} title={rowData.name}>
                        {rowData.name}
                      </div>
                    </ButtonListRedirect>
                    <div className={styles.subCell}>{rowData.createdAt}</div>
                  </>
                )}
              </Table.Cell>
            </Table.Column>

            <Table.ColumnGroup header={"Następne generowanie"}>
              <Table.Column width={125} resizable>
                <Table.HeaderCell>Data</Table.HeaderCell>
                <Table.Cell>
                  {(rowData) => {
                    return rowData.nextGenDate;
                  }}
                </Table.Cell>
              </Table.Column>
              <Table.Column width={100} resizable>
                <Table.HeaderCell>Godzina</Table.HeaderCell>
                <Table.Cell>
                  {(rowData) => {
                    return rowData.genTime;
                  }}
                </Table.Cell>
              </Table.Column>
            </Table.ColumnGroup>

            <Table.Column flexGrow={1} resizable>
              <Table.HeaderCell>Odbiorcy</Table.HeaderCell>
              <Table.Cell className={styles.alertCellWraper}>
                {(rowData) => {
                  const recipients = rowData.recipients.map(
                    (r) =>
                      data.roles.find((role) => role.id === r.roleId)?.name ??
                      ""
                  );
                  return (
                    <div
                      style={{ width: "100%" }}
                      title={recipients.join("\n")}>
                      {recipients.join(", ")}
                    </div>
                  );
                }}
              </Table.Cell>
            </Table.Column>

            <Table.Column flexGrow={1} minWidth={150} resizable>
              <Table.HeaderCell>Aktywności</Table.HeaderCell>
              <Table.Cell className={styles.alertCellWraper}>
                {(rowData) => {
                  if (!rowData.activities.length)
                    return <div style={{ color: "#aaa" }}>{"Wszystkie"}</div>;
                  const activities = rowData.activities.map(
                    (a) =>
                      data.activities.find(
                        (activity) => activity.id === a.prefix
                      )?.name ?? ""
                  );
                  return (
                    <div
                      style={{ width: "100%" }}
                      title={activities.join("\n")}>
                      {activities.join(", ")}
                    </div>
                  );
                }}
              </Table.Cell>
            </Table.Column>

            <Table.Column align="right" fixed={"right"}>
              <Table.HeaderCell>Opcje</Table.HeaderCell>
              <Table.Cell align={"right"}>
                {(rowData: RowDataType<IAlertEntity>) => {
                  return (
                    <>
                      <IconButton
                        icon={"trash"}
                        inline={true}
                        onClick={() => onDelete(rowData.id, rowData.name)}
                      />
                      <IconButton
                        icon={"settings"}
                        inline={true}
                        onClick={handleChangeToEdit.bind(
                          null,
                          rowData as IAlertEntity
                        )}
                      />
                    </>
                  );
                }}
              </Table.Cell>
            </Table.Column>
          </Table>
        }
        pagination={
          <Pagination
            count={data?.count ?? 0}
            limit={state.requestPaginate.limit.toString()}
            page={state.requestPaginate.page.toString()}
            setState={setState}
          />
        }
      />

      <UniversalFilters
        load={AlertsConnection.list}
        setLoading={setLoading}
        state={state}
        setState={setState}
        triggerLoad={triggerLoad}
        defaultStateData={defaultState}
        setResultData={setData}
        filterStorageKey={`projectAlerts_${getProjectIdFromUrl() ?? "global"}`}
        elements={[
          {
            type: "input_text",
            stateKey: "name",
            label: "Nazwa",
          },
        ]}
      />
    </>
  );
};

export default Alerts;
