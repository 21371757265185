import React, { FunctionComponent, useEffect, useState } from "react";
import { ICreateProduct, IProjectProductFormData } from "utils/models";
import { createProductState } from "utils/states";
import {
  generatePath,
  matchPath,
  useHistory,
  useParams,
  useLocation,
} from "react-router-dom";
import { deepClone, getProjectIdFromUrl, handleToast } from "utils/helpers";
import WhiteCard from "global/atoms/WhiteCard";
import ProductsConnection from "utils/connections/products";
import ActionsContainer from "global/atoms/ActionsContainer";
import ButtonSubmit from "global/atoms/ButtonSubmit";
import Input from "global/atoms/Input";
import Select from "global/atoms/Select";
import Spinner from "global/atoms/Spinner/Spinner";
import SeparatorEmpty from "../../../../global/atoms/separators/SeparatorEmpty";
import ImageFormSelect from "../../../../global/atoms/ImageFormSelect";
import IconSvg from "../../../../global/atoms/IconHelper";
import {
  faBoxes,
  faCompressArrowsAlt,
  faMoneyBillWave,
} from "@fortawesome/free-solid-svg-icons";
import { AxiosPromise } from "axios";
import ProjectsConnection from "../../../../utils/connections/projects";
import { Col, Form, Panel, Row, TagPicker } from "rsuite";

interface IAddProducts {}

const ProductForm: FunctionComponent<IAddProducts> = () => {
  const location = useLocation();
  const [data, setData] = useState<ICreateProduct>(createProductState);
  const [errors, setErrors] = useState<any | null>(null);
  const [form, setForm] = useState<IProjectProductFormData>();
  const history = useHistory();

  const { id } = useParams<{ id: string }>();
  const { productId } = useParams<{ productId: string }>();

  const isProjectProduct = (): boolean => {
    return matchPath(location.pathname, "/projects/:id/edit/products") !== null;
  };

  const getProductId = (): string | undefined => {
    if (isProjectProduct()) return productId;
    return id;
  };

  if (getProductId() !== undefined) {
    useEffect(() => {
      ProductsConnection.getFormEditProduct(getProductId() ?? "").then(
        (data) => {
          const model = data.data.model;
          setData({
            ...model,
            customerId: model.customer.id,
            amount: model.price.amount,
            brandId: model.brand.id,
            currency: model.price.currency,
            height: model.dimension.height,
            length: model.dimension.length,
            width: model.dimension.width,
            itemGroupId: model.itemGroup.id,
            itemUnitId: model.itemUnit.id,
            itemVatRateId: model.itemVatRate.id,
            itemCategoryId: model.itemCategory?.id,
            volumeUnitId: model.volumeUnit?.id,
          });
          setForm(data.data);
        }
      );
    }, []);
  } else {
    useEffect(() => {
      const getDefaultValue = (data: Array<any>) => data.find(() => true).id;
      ProductsConnection.getFormAddProduct().then((data) => {
        const formData = data.data;
        setForm(formData);
        setData({
          ...createProductState,
          itemGroupId: getDefaultValue(formData.itemGroups.options),
          itemUnitId: getDefaultValue(formData.itemUnits.options),
          itemVatRateId: getDefaultValue(formData.itemVatRates.options),
        });
      });
    }, []);
  }

  const handleSubmit = (event: any) => {
    event.preventDefault();
    const dataClone = deepClone(data);
    dataClone.length = parseInt(dataClone.length.toString());
    dataClone.width = parseInt(dataClone.width.toString());
    dataClone.height = parseInt(dataClone.height.toString());
    dataClone.countInBag = parseInt(dataClone.countInBag.toString());
    dataClone.assignToProjects =
      dataClone.assignToProjects?.map((el) => ({
        id: el,
      })) ?? [];

    let promise: AxiosPromise<any>;
    if (isProjectProduct())
      promise = ProjectsConnection.updateProjectProduct(
        getProductId() ?? "",
        dataClone
      );
    else if (getProductId())
      promise = ProductsConnection.updateProduct(id, dataClone);
    else promise = ProductsConnection.createProduct(dataClone);

    handleToast(promise, setErrors).then(() => {
      if (isProjectProduct())
        history.push(
          generatePath("/projects/:id/edit/products", {
            id: getProjectIdFromUrl() ?? "",
          })
        );
      else history.push("/products/product");
    });
  };
  if (!form) return <Spinner />;
  const getProjectOptions = () => {
    if (form.model?.customer?.customerType === "ORGANIZATION") {
      return form.projects.options;
    }
    return form.projects.options.filter(
      (el) => el.customerId == data.customerId
    );
  };

  return (
    <>
      <Form>
        <WhiteCard>
          <Col xs={3}>
            <ImageFormSelect
              label={"Wybierz zdjęcie"}
              state={data}
              sizePx={160}
              setState={setData}
              name={"photo"}
            />
          </Col>
          <Col xs={21}>
            <Row>
              <Col xs={24}>
                <Input
                  type={"text"}
                  placeholder={"Nazwa"}
                  name={"name"}
                  value={data.name}
                  state={data}
                  setState={setData}
                  errors={errors}
                />
              </Col>
              <Col xs={8}>
                <Select
                  placeholder={"Grupa"}
                  name={"itemGroupId"}
                  value={data.itemGroupId}
                  options={form.itemGroups.options}
                  state={data}
                  setState={setData}
                  errors={errors}
                />
              </Col>
              <Col xs={8}>
                <Input
                  type={"number"}
                  placeholder={"GTIN(EAN)"}
                  name={"gtin"}
                  value={data.gtin}
                  state={data}
                  setState={setData}
                  errors={errors}
                />
              </Col>
              <Col xs={8}>
                <Select
                  placeholder={"Marka"}
                  name={"brandId"}
                  value={data.brandId}
                  options={form.brands.options}
                  state={data}
                  setState={setData}
                  errors={errors}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={4}>
                <Select
                  placeholder={"Jednostka"}
                  name={"itemUnitId"}
                  value={data.itemUnitId}
                  options={form.itemUnits.options}
                  state={data}
                  setState={setData}
                  errors={errors}
                />
              </Col>
              <Col xs={10}>
                <Select
                  disabled={!!id}
                  placeholder={"Klient"}
                  name={"customerId"}
                  value={data.customerId}
                  options={form.customers.options}
                  onChange2={(selectedOption) => {
                    setData({
                      ...data,
                      customerId: selectedOption.value.toString(),
                      itemCategoryId: "",
                    });
                  }}
                  errors={errors}
                />
              </Col>
              <Col xs={10}>
                <Select
                  placeholder={"Kategoria"}
                  name={"itemCategoryId"}
                  value={data.itemCategoryId}
                  disabled={!data.customerId}
                  options={form.itemCategories.options.filter(
                    (o) => o.customerId === data.customerId
                  )}
                  state={data}
                  setState={setData}
                  errors={errors}
                />
              </Col>
            </Row>
          </Col>
        </WhiteCard>
        <SeparatorEmpty />
        <WhiteCard>
          <Panel bodyFill>
            <Row>
              <Col xs={1}>{IconSvg(faMoneyBillWave)}</Col>
              <Col xs={7}>
                <Input
                  type={"number"}
                  placeholder={"Cena netto"}
                  name={"amount"}
                  value={data.amount}
                  state={data}
                  setState={setData}
                  errors={errors}
                />
              </Col>
              <Col xs={8}>
                <Select
                  placeholder={"Waluta"}
                  name={"currency"}
                  value={data.currency}
                  options={form.currencies.options}
                  state={data}
                  setState={setData}
                  errors={errors}
                />
              </Col>
              <Col xs={8}>
                <Select
                  placeholder={"Stawka VAT"}
                  name={"itemVatRateId"}
                  value={data.itemVatRateId}
                  options={form.itemVatRates.options}
                  state={data}
                  setState={setData}
                  errors={errors}
                />
              </Col>
            </Row>
          </Panel>
          <Panel bodyFill>
            <Row>
              <Col xs={1}>{IconSvg(faCompressArrowsAlt)}</Col>
              <Col xs={7}>
                <Input
                  type={"number"}
                  placeholder={"Długość"}
                  name={"length"}
                  value={data.length}
                  state={data}
                  setState={setData}
                  errors={errors}
                />
              </Col>
              <Col xs={8}>
                <Input
                  type={"number"}
                  placeholder={"Szerokość"}
                  name={"width"}
                  value={data.width}
                  state={data}
                  setState={setData}
                  errors={errors}
                />
              </Col>
              <Col xs={8}>
                <Input
                  type={"number"}
                  placeholder={"Wysokość"}
                  name={"height"}
                  value={data.height}
                  state={data}
                  setState={setData}
                  errors={errors}
                />
              </Col>
            </Row>
          </Panel>
          <Panel bodyFill>
            <Row>
              <Col xs={1}>{IconSvg(faBoxes)}</Col>
              <Col xs={7}>
                <Input
                  type={"number"}
                  placeholder={"Ilość w opakowaniu"}
                  name={"countInBag"}
                  value={data.countInBag}
                  state={data}
                  setState={setData}
                  errors={errors}
                />
              </Col>
              <Col xs={8}>
                <Input
                  type={"number"}
                  placeholder={"Gramatura"}
                  name={"volume"}
                  value={data.volume}
                  state={data}
                  setState={setData}
                  errors={errors}
                />
              </Col>
              <Col xs={8}>
                <Select
                  placeholder={"Jednostka gramatury"}
                  name={"volumeUnitId"}
                  disabled={!data.volume}
                  value={data.volumeUnitId}
                  options={form.itemUnits.options}
                  state={data}
                  setState={setData}
                  errors={errors}
                />
              </Col>
            </Row>
          </Panel>
        </WhiteCard>
        <SeparatorEmpty />
        <Panel
          shaded
          style={{ backgroundColor: "#fff" }}
          header={
            "Wybierz projekty, do których, produkt zostanie automatycznie przypisany"
          }>
          <Row>
            <Col xs={24}>
              <Form.Group>
                {/*              <Form.ControlLabel>
                Wybierz projekty, do których, produkt zostanie automatycznie
                przypisany
              </Form.ControlLabel>*/}
                <Form.Control
                  name={"projectIds"}
                  accepter={TagPicker}
                  block
                  valueKey={"projectId"}
                  labelKey={"projectName"}
                  data={getProjectOptions()}
                  placeholder={"Wybierz projekt"}
                  errorMessage={errors?.projects}
                  errorPlacement={"bottomEnd"}
                  onChange={(values) => {
                    setData({ ...data, assignToProjects: values });
                  }}
                />
              </Form.Group>
            </Col>
          </Row>
        </Panel>
        <SeparatorEmpty size={1.5} />
        <ActionsContainer>
          <ButtonSubmit
            form={form}
            style={{ width: "150px" }}
            onClick={handleSubmit}
          />
        </ActionsContainer>
      </Form>
    </>
  );
};

export default ProductForm;
