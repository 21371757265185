import React, { memo } from "react";
import "./QuestionTypeConsts";
import {
  FCC,
  IQuestionDictionaryValue,
  IQuestionItem,
} from "../../../../../../utils/models";
import {
  DatePicker,
  Form,
  Input,
  InputNumber,
  SelectPicker,
  TagPicker,
} from "rsuite";
import { cloneDeep } from "lodash";
import {
  BOOLEAN_QUESTION,
  DATE_QUESTION,
  DECIMAL_QUESTION,
  DICTIONARY_MULTIPLE_ANSWERS_QUESTION,
  DICTIONARY_QUESTION,
  INTEGER_QUESTION,
  PHOTO_QUESTION,
  TEXTAREA_QUESTION,
  TEXT_QUESTION,
  TIME_QUESTION,
} from "./QuestionTypeConsts";
import PhotoQuestion from "./Atoms/PhotoQuestion";
import CloseIcon from "@rsuite/icons/Close";
import TextArea from "./Atoms/TextArea";
import { parseToDate, useFormStateContext } from "./VisitHelpers";

interface IVisitQuestion {
  question: IQuestionItem;
  isDisabled: boolean;
}

const VisitQuestion: FCC<IVisitQuestion> = ({ question, isDisabled }) => {
  const formState = useFormStateContext();
  const formValue = formState?.[question.refQuestionId];

  const renderIntegerInput = () => {
    return (
      <Form.Control
        accepter={InputNumber}
        name={question.refQuestionId}
        min={0}
        step={1}
        disabled={isDisabled}
      />
    );
  };

  const renderDecimalInput = () => {
    return (
      <Form.Control
        value={formValue as string}
        accepter={Input}
        name={question.refQuestionId}
        disabled={isDisabled}
      />
    );
  };

  const renderTextInput = () => {
    return (
      <Form.Control
        accepter={Input}
        type={"text"}
        value={formValue as string}
        placeholder={"Wpisz odpowiedź"}
        name={question.refQuestionId}
        max={question.maxValue ? question.maxValue.toString() : 10000}
        disabled={isDisabled}
      />
    );
  };

  const renderPhotoQuestion = () => {
    return (
      <PhotoQuestion
        //TODO display value of photoQuestion
        key={`photo-question-${question.refQuestionId}`}
        refQuestionId={question.refQuestionId}
        isDisabled={isDisabled}
      />
    );
  };

  const renderBooleanQuestion = () => {
    const options = [
      { value: "1", label: "Tak", refQuestionAnswerId: "" },
      { value: "0", label: "Nie", refQuestionAnswerId: "" },
    ];

    return (
      <Form.Control
        accepter={SelectPicker}
        searchable={false}
        cleanable={true}
        data={options}
        block
        value={formValue}
        labelKey={"label"}
        valueKey={"value"}
        placeholder={"Wybierz"}
        name={question.refQuestionId}
        disabled={isDisabled}
      />
    );
  };

  const renderTextareaInput = () => (
    <Form.Control
      value={formValue as string}
      accepter={TextArea}
      name={question.refQuestionId}
      disabled={isDisabled}
      placeholder={"Wpisz odpowiedź"}
      style={{ width: "100%", height: "60px" }}
      // @ts-ignore
      max={question.maxValue ? parseInt(question.maxValue.toString()) : 10000}
      rows={3}
    />
  );

  const renderDictionaryQuestion = () => {
    const options = cloneDeep(question.options);

    question.values.every((_v) => {
      const v = _v as IQuestionDictionaryValue;
      if (!options.find((o) => o.id === v.value)) {
        if (v?.name) {
          options.push({
            id: v.value,
            name: v.name,
            value: v.value,
          });
        }
      }
    });

    if (question.questionType === DICTIONARY_QUESTION) {
      return (
        <Form.Control
          accepter={SelectPicker}
          searchable={false}
          cleanable={true}
          block
          value={formValue}
          style={{ minWidth: "200px" }}
          data={options}
          placeholder={"Wybierz odpowiedź"}
          name={question.refQuestionId}
          labelKey={"name"}
          valueKey={"id"}
          disabled={isDisabled}
        />
      );
    } else {
      // DICTIONARY_MULTIPLE_ANSWERS_QUESTION only
      return (
        <Form.Control
          accepter={TagPicker}
          block
          value={formValue}
          name={question.refQuestionId}
          style={{ minWidth: "200px" }}
          labelKey={"name"}
          valueKey={"id"}
          data={options}
          placeholder={"Wybierz odpowiedzi"}
          disabled={isDisabled}
        />
      );
    }
  };

  const renderDatePicker = () => {
    return (
      <Form.Control
        isoWeek
        showWeekNumbers
        ranges={[
          {
            label: "Dziś",
            value: new Date(),
          },
        ]}
        value={parseToDate(formValue as string | Date)}
        style={{ width: "50%" }}
        oneTap
        accepter={DatePicker}
        name={question.refQuestionId}
        format={"yyyy-MM-dd"}
        disabled={isDisabled}
      />
    );
  };

  const renderTimePicker = () => {
    return (
      <Form.Control
        accepter={DatePicker}
        format="HH:mm"
        isoWeek
        //time is stored in state as date
        value={parseToDate(formValue as string | Date)}
        name={question.refQuestionId}
        disabled={isDisabled}
        ranges={[
          {
            label: "Teraz",
            value: new Date(),
          },
        ]}
        style={{ width: "50%" }}
      />
    );
  };

  const renderElement = () => {
    switch (question.questionType) {
      case INTEGER_QUESTION:
        return renderIntegerInput();
      case DECIMAL_QUESTION:
        return renderDecimalInput();
      case TEXT_QUESTION:
        return renderTextInput();
      case PHOTO_QUESTION:
        return renderPhotoQuestion();
      case BOOLEAN_QUESTION:
        return renderBooleanQuestion();
      case TEXTAREA_QUESTION:
        return renderTextareaInput();
      case DICTIONARY_MULTIPLE_ANSWERS_QUESTION:
      case DICTIONARY_QUESTION:
        return renderDictionaryQuestion();
      case DATE_QUESTION:
        return renderDatePicker();
      case TIME_QUESTION:
        return renderTimePicker();
      default:
        return <CloseIcon />;
    }
  };

  return renderElement();
};

export default memo(VisitQuestion);
