import { IAlertEntityStore, IGetAlertList } from "../models";
import {
  getBaseApiUrl,
  getOrganizationId,
  getProjectIdFromUrl,
} from "../helpers";
import AxiosHelper, { AxiosManager, HttpMethod } from "../AxiosHelper";
import { AxiosPromise } from "axios";

const baseUrl = `${getBaseApiUrl()}/organizations/${getOrganizationId()}/notifications`;

const AlertsConnection = {
  list: (data: IGetAlertList) => {
    // NotificationProjectReportViewController->getAll
    const projectId = getProjectIdFromUrl() ?? "global";
    return AxiosHelper.post(`${baseUrl}/${projectId}/reports/list`, data);
  },
  form: (projectIds: Array<string>) => {
    return AxiosHelper.post(`${baseUrl}/reports/form`, projectIds);
  },
  edit: (data: IAlertEntityStore) => {
    return AxiosManager.send(
      HttpMethod.post,
      `${baseUrl}/reports/${data.id}/edit`,
      data
    );
  },
  add: (data: IAlertEntityStore) => {
    return AxiosHelper.post(`${baseUrl}/reports`, data);
  },
  get: (alertId: string): AxiosPromise<IAlertEntityStore> => {
    return AxiosHelper.get(`${baseUrl}/reports/${alertId}/form`);
  },
  delete: (alertId: string): AxiosPromise => {
    return AxiosHelper.delete(`${baseUrl}/reports/${alertId}`);
  },
  superAdminForm: (notificationId: string) => {
    return AxiosHelper.get(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/projects/${getProjectIdFromUrl()}/notifications/${notificationId}/manual-form`
    );
  },
  superAdminManualGenerate: (notificationId: string, data: any) => {
    return AxiosHelper.post(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/notifications/${notificationId}/manual`,
      data
    );
  },
};

export default AlertsConnection;
