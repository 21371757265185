import React, { memo } from "react";
import { FunctionComponent } from "react";
import {
  Form,
  Panel,
  Whisper,
  Tooltip,
  Input,
  PanelGroup,
  Badge,
} from "rsuite";
import { IVisitCommentItem } from "../VisitEdit";
import { isEmpty } from "lodash";
import SeparatorLine from "global/atoms/separators/SeparatorLine";
import Comment from "./Atoms/Comment";
import { isPublicView } from "utils/helpers";

interface IVisitComment {
  refQuestionId: string | null;
  disabled: boolean;
  comments: IVisitCommentItem[];
}

const VisitComment: FunctionComponent<IVisitComment> = (props) => {
  function BadgeWrapper(props: { len: number }) {
    return (
      <div style={{ fontSize: "0.85em" }}>
        {"Komentarze"}{" "}
        {props.len ? <Badge color={"orange"} content={props.len} /> : ""}
      </div>
    );
  }

  if (isPublicView() && !props.comments.length) return <></>;
  return (
    <PanelGroup classPrefix="comment-panel-group">
      <Panel
        eventKey={`visit-comment-${props.refQuestionId}`}
        classPrefix="comment-panel"
        defaultExpanded={isPublicView()}
        style={{ marginTop: "8px" }}
        header={
          <Whisper
            placement="auto"
            speaker={<Tooltip>Rozwiń, aby dodać nowy</Tooltip>}>
            <BadgeWrapper len={props.comments.length} />
          </Whisper>
        }
        collapsible>
        {!isPublicView() && (
          <>
            <Form.Group>
              <Form.ControlLabel>Nowy komentarz</Form.ControlLabel>
              <Form.Control
                name={`visitCommentQuestion.${props.refQuestionId}`}
                accepter={Input}
                style={{ width: "100%" }}
                disabled={props.disabled}
              />
            </Form.Group>
            {!isEmpty(props.comments) && <SeparatorLine />}
          </>
        )}

        {props.comments.map((commentData, key) => (
          <Comment
            key={`question-comment-${key}`}
            author={commentData.userName}
            date={commentData.createdAt}
            content={commentData.comment}
          />
        ))}
      </Panel>
    </PanelGroup>
  );
};

export default memo(VisitComment);
