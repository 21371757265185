import React, { FunctionComponent, useEffect, useState } from "react";
import Select from "../../../../global/atoms/Select";
import SeparatorEmpty from "../../../../global/atoms/separators/SeparatorEmpty";
import DoubleSidePicker from "../../../../global/atoms/dooubleSidePicker/DoubleSidePicker";
import { doubleSidePickerOnChange } from "../../../../utils/helpers";
import UniversalModal, {
  getQuestionConfigTitle,
} from "../../../../global/atoms/UniversalModal";
import ActionsContainer from "../../../../global/atoms/ActionsContainer";
import ButtonGoBack from "../../../../global/atoms/ButtonGoBack";
import { Button, Col, Dropdown, Message, Row } from "rsuite";
import { confirmModalHandler } from "../../../../redux/store";
import QuestionForm from "./QuestionForm";
import {
  IFormActivityState,
  IFormTaskActivityEdit,
  IProjectSettings,
  IQuestionItem,
  SetState,
} from "../../../../utils/models";
import { useSelector } from "react-redux";
import { IRoot } from "../../../../redux/models";
import { ITaskActivityEditErrors } from "./TaskActivities";
import settings from "../../../../assets/svg/settings.svg";
import { getQuestionIconByType } from "../../../../global/atoms/IconHelper";
import {
  BOOLEAN_QUESTION,
  INTEGER_QUESTION,
} from "../visits/visitsEdit/VisitElements/QuestionTypeConsts";
import { TypeListingType } from "../../../../utils/types";
import ListingDrawer from "./listing/ListingDrawer";
import { Prompt } from "react-router-dom";
import ActivityHeaderForm from "./ActivityHeaderForm";

interface IActivityItemForm {
  state: IFormActivityState;
  setState: SetState<IFormActivityState>;
  form: IFormTaskActivityEdit;
  setForm: SetState<IFormTaskActivityEdit | null>;
  projectSettings: IProjectSettings;
  handleSubmit: (
    stateItem: IFormActivityState,
    formItem: IFormTaskActivityEdit,
    submitCallback: () => void
  ) => void;
  onClose: () => void;
}

const ActivityItemForm: FunctionComponent<IActivityItemForm> = ({
  state,
  setState,
  form,
  setForm,
  projectSettings,
  handleSubmit,
  onClose,
}) => {
  const TYPE_ITEM = "ITEM";
  const TYPE_GENERAL = "GENERAL";
  const TYPE_SALE = "SALE";
  const TYPE_ORDER = "ORDER";
  const TYPE_STORE = "STORE";

  const [modalOpen, setModalOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [pickedQuestion, setPickedQuestion] = useState<IQuestionItem>();
  const [clearPickedQuestionTrigger, setClearPickedQuestionTrigger] =
    useState(0);
  const errors: ITaskActivityEditErrors = useSelector(
    (root: IRoot) => root.formErrors
  );
  const [drawerOn, setDrawerOn] = useState<TypeListingType | null>(null);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => setLoaded(true), []);
  useEffect(() => {
    if (loaded) setUnsavedChanges(true);
  }, [state, form]);

  const getModalBody = (): JSX.Element => {
    if (!pickedQuestion || !form) return <></>;
    return (
      <>
        <QuestionForm
          question={pickedQuestion}
          assigned={form.assignedQuestions.data}
          removePicked={() => {
            setModalOpen(false);
            setClearPickedQuestionTrigger(new Date().getTime());
          }}
          setForm={setForm}
          form={form}
          defaultValueEdit={false}
          type={"default"}
        />
      </>
    );
  };

  const handlePickedQuestion = (questionId: string) => {
    const question: IQuestionItem | undefined =
      form.assignedQuestions.data.find(
        // @ts-ignore
        (q) => q.id === questionId
      );
    if (!question) throw "handlePickedQuestion - picked question not found";
    setPickedQuestion(question);
    setModalOpen(true);
  };

  const submitConfirmAlert = (
    <Message showIcon type={"info"}>
      Zmiany wzoru aktywności dla ankiet poza planem, będą widoczne w ankietach
      wygenerowanych po wykonaniu edycji. Dla ankiet planowanych po
      automatycznej aktualizacji harmonogramu. Ankiety wygenerowane przed
      wprowadzeniem zmian w aktywności nie zmienią się.
    </Message>
  );

  const isItemType = (): boolean => {
    return (
      form?.model && form.model.activityTypeType.toUpperCase() === TYPE_ITEM
    );
  };
  const isGeneralType = (): boolean => {
    return (
      form?.model && form.model.activityTypeType.toUpperCase() === TYPE_GENERAL
    );
  };
  console.log(state);
  return (
    <>
      <Prompt
        message={"Nie zapisałeś zmian, czy na pewno chcesz opuścić edycje?"}
        when={unsavedChanges}
      />

      <Row style={{ rowGap: "15px" }}>
        <ActivityHeaderForm
          state={state}
          setState={setState}
          form={form}
          errors={errors}
          isEdit
        />
        <SeparatorEmpty />
        <Col xs={24}>
          {((form?.model &&
            form.model.activityTypeType.toUpperCase() === TYPE_ITEM) ||
            form?.model.activityTypeType.toUpperCase() === TYPE_ORDER) && (
            <Select
              placeholder={"Funkcja dodatkowa"}
              name={"additionalFeatureId"}
              value={state?.additionalFeatureId}
              disabled={false}
              options={form.additionalFeatures.options}
              state={state}
              setState={setState}
              errors={{
                additionalFeatureId: errors?.feature_proposal ?? null,
              }}
            />
          )}
        </Col>
      </Row>

      {state?.additionalFeatureId === "PROPOSAL" &&
        form.model.activityTypeType.toUpperCase() === TYPE_ITEM && (
          <>
            <Message type="info" header="Zamówienie sugerowane">
              Opcja generowania zamówień sugerowanych, wymaga uzupełnienia
              pytania typu liczbowego lub logicznego, z oznaczonym parametrem
              “Generuj pozycję zamówienia”.
              <br />- Przypisz pytanie liczbowe (
              {getQuestionIconByType(INTEGER_QUESTION)}) lub logiczne (
              {getQuestionIconByType(BOOLEAN_QUESTION)}) do aktywności
              <br />
              - Kliknij ikonę <img src={settings} alt={"Ustawienia"} />, aby
              ustawić parametry pytania.
            </Message>
          </>
        )}

      {form?.model &&
        form.model.activityTypeType.toUpperCase() !== TYPE_STORE &&
        form.model.activityTypeType.toUpperCase() !== TYPE_SALE &&
        form.model.activityTypeType.toUpperCase() !== TYPE_ORDER && (
          <div>
            <SeparatorEmpty size={0.5} />
            <DoubleSidePicker
              heading={"Pytania"}
              emptyMessageAssigned={"Brak przypisanych pytań"}
              emptyMessageNotAssigned={"Brak pytań w projekcie"}
              assigned={form.assignedQuestions.data}
              unassigned={form.unassignedQuestions.data}
              itemType={"taskQuestion"}
              clearPickedItemTrigger={clearPickedQuestionTrigger}
              pickedItemCallback={handlePickedQuestion}
              onChange={(assignedIds, unAssignedIds, cb) => {
                doubleSidePickerOnChange(
                  assignedIds,
                  unAssignedIds,
                  "assignedQuestions",
                  "unassignedQuestions",
                  form,
                  setForm,
                  cb
                );
              }}
            />
            <UniversalModal
              title={getQuestionConfigTitle(pickedQuestion)}
              open={modalOpen}
              body={getModalBody()}
              displayFooterButtons={false}
            />
          </div>
        )}

      {form?.model &&
        (form.model.activityTypeType.toUpperCase() === TYPE_STORE ||
          form.model.activityTypeType.toUpperCase() === TYPE_ITEM ||
          form.model.activityTypeType.toUpperCase() === TYPE_ORDER ||
          form.model.activityTypeType.toUpperCase() === TYPE_SALE) && (
          <div>
            <SeparatorEmpty size={0.5} />
            <DoubleSidePicker
              heading={"Produkty i materiały"}
              emptyMessageAssigned={"Brak przypisanych produktów"}
              emptyMessageNotAssigned={"Brak produktów w projekcie"}
              unassigned={form.unassignedItems.data}
              assigned={form.assignedItems.data}
              itemType={"product"}
              onChange={(assignedIds, unAssignedIds, cb) => {
                doubleSidePickerOnChange(
                  assignedIds,
                  unAssignedIds,
                  "assignedItems",
                  "unassignedItems",
                  form,
                  setForm,
                  cb
                );
              }}
            />
          </div>
        )}

      <div>
        <SeparatorEmpty size={0.5} />
        <DoubleSidePicker
          heading={"Lokalizacje"}
          emptyMessageAssigned={"Brak przypisanych lokalizacji"}
          emptyMessageNotAssigned={"Brak lokalizacji w projekcie"}
          unassigned={form?.unassignedLocations.data ?? []}
          assigned={form?.assignedLocations.data ?? []}
          form={form}
          itemType={"taskLocation"}
          onChange={(assignedIds, unAssignedIds, cb) => {
            doubleSidePickerOnChange(
              assignedIds,
              unAssignedIds,
              "assignedLocations",
              "unassignedLocations",
              form,
              setForm,
              cb
            );
          }}
        />
      </div>
      <SeparatorEmpty />
      <ActionsContainer
        style={{ marginBottom: "24px" }}
        align={"space-between"}>
        {
          //@ts-ignore - dropdown as button
          <Dropdown
            appearance={"ghost"}
            title="Opcje zaawansowane"
            placement={"bottomEnd"}>
            {isItemType() && projectSettings.isEnableItemNetworkListing && (
              <Dropdown.Item onClick={() => setDrawerOn("product")}>
                Listing produktów
              </Dropdown.Item>
            )}
            {(isItemType() || isGeneralType()) &&
              projectSettings.isEnableQuestionNetworkListing && (
                <Dropdown.Item onClick={() => setDrawerOn("question")}>
                  Listing pytań
                </Dropdown.Item>
              )}
            {isItemType() &&
              projectSettings.isEnableItemLevelNetworkListing && (
                <Dropdown.Item onClick={() => setDrawerOn("product-levels")}>
                  Minimalne stany produktów
                </Dropdown.Item>
              )}
            {isItemType() &&
              projectSettings.isEnableItemHighlightNetworkListing && (
                <Dropdown.Item onClick={() => setDrawerOn("itemHighlight")}>
                  Produkty wyróżnione
                </Dropdown.Item>
              )}
          </Dropdown>
        }

        <ActionsContainer>
          <ButtonGoBack onClick={onClose} />
          <Button
            appearance={"primary"}
            type={"button"}
            loading={submitting}
            disabled={submitting}
            onClick={confirmModalHandler(submitConfirmAlert, () => {
              if (!state || !form) return;
              setSubmitting(true);
              setUnsavedChanges(false);
              handleSubmit(state, form, () => {
                onClose();
                setSubmitting(false);
              });
            })}>
            {"Zapisz"}
          </Button>
        </ActionsContainer>
      </ActionsContainer>
      <SeparatorEmpty />
      <ListingDrawer
        activityId={state.activityId}
        listingType={drawerOn}
        onClose={() => setDrawerOn(null)}
      />
    </>
  );
};

export default ActivityItemForm;
