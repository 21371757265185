import React, { FunctionComponent, useState } from "react";
import { IGetRoles, IRoles } from "utils/models";
import { getRolesState } from "utils/states";
import Pagination from "global/pagination/Pagination";
import RolesConnection from "utils/connections/roles";
import Table from "rsuite/Table";
import settings from "assets/svg/settings.svg";
import { useHistory } from "react-router-dom";
import styles from "./styles.module.scss";
import HeaderButtons from "global/atoms/headerButtons/HeaderButtons";
import UniversalFilters, {
  FILTER_INPUT_TEXT,
} from "../../../../global/filters/UniversalFilters";
import NavOptionsWrapper from "../../../../utils/NavOptionsWrapper";
import { handleSortColumn } from "../../../../utils/helpers";
import PaginatedTableViewWrapper from "global/PaginatedTableViewWrapper";

interface IRoleList {}

const RoleList: FunctionComponent<IRoleList> = () => {
  const [data, setData] = useState<IRoles | null>(null);
  const [filters, setFilters] = useState<IGetRoles>(getRolesState);
  const [loading, setLoading] = useState(true);
  const history = useHistory();

  const handleEdit = (event: any) => {
      history.push(`/admin/roles/${event.currentTarget.dataset.id}/edit`);
    },
    handleRowClick = (data: any) => {
      history.push(`/admin/roles/${data.roleId}/edit`);
    };

  return (
    <>
      <HeaderButtons>
        <NavOptionsWrapper addPath={"/admin/roles/add"} />
      </HeaderButtons>

      <PaginatedTableViewWrapper
        table={
          <Table
            fillHeight
            data={data?.data ?? []}
            onRowClick={handleRowClick}
            loading={loading}
            sortColumn={filters.requestOrder.field}
            sortType={filters.requestOrder.order}
            onSortColumn={(dataKey, sortType) =>
              handleSortColumn(dataKey, sortType, setFilters)
            }>
            <Table.Column flexGrow={1} align="left" sortable>
              <Table.HeaderCell>Nazwa</Table.HeaderCell>
              <Table.Cell dataKey="roleName" />
            </Table.Column>
            <Table.Column flexGrow={1} sortable>
              <Table.HeaderCell>Grupa</Table.HeaderCell>
              <Table.Cell dataKey="roleType">
                {(rowData) => {
                  return (
                    <span
                      className={styles.status}
                      style={{
                        color: rowData.roleType.color,
                        borderColor: rowData.roleType.color,
                      }}>
                      {rowData.roleType.name}
                    </span>
                  );
                }}
              </Table.Cell>
            </Table.Column>
            <Table.Column width={200} sortable>
              <Table.HeaderCell>Priorytet grupy</Table.HeaderCell>
              <Table.Cell dataKey="hierarchyLevel" />
            </Table.Column>
            <Table.Column flexGrow={1} sortable>
              <Table.HeaderCell>Rap. czasu pracy</Table.HeaderCell>
              <Table.Cell dataKey="isEnableWorkTimeHistory">
                {(rowData) => (
                  <span
                    className={`${styles.status} ${
                      rowData.isEnableWorkTimeHistory ? styles.statusActive : ""
                    }`}>
                    {rowData.isEnableWorkTimeHistory ? "TAK" : "NIE"}
                  </span>
                )}
              </Table.Cell>
            </Table.Column>
            <Table.Column
              verticalAlign={"middle"}
              align="right"
              fixed={"right"}>
              <Table.HeaderCell>Akcje</Table.HeaderCell>
              <Table.Cell>
                {(rowData) => (
                  <img
                    src={settings}
                    style={{ cursor: "pointer" }}
                    alt={"Idź do"}
                    data-id={rowData.roleId}
                    onClick={handleEdit}
                  />
                )}
              </Table.Cell>
            </Table.Column>
          </Table>
        }
        pagination={
          <Pagination
            count={data?.count ?? 0}
            page={filters.requestPaginate.page.toString()}
            limit={filters.requestPaginate.limit.toString()}
            setState={setFilters}
          />
        }
      />

      <UniversalFilters
        load={RolesConnection.getRoles}
        formGet={RolesConnection.getFilterForm}
        setLoading={setLoading}
        state={filters}
        setState={setFilters}
        defaultStateData={getRolesState}
        setResultData={setData}
        filterStorageKey={"adminRoleList"}
        elements={[
          {
            type: FILTER_INPUT_TEXT,
            label: "Nazwa",
            stateKey: "name",
          },
        ]}
      />
    </>
  );
};

export default RoleList;
