import React, { FunctionComponent } from "react";
import { Nav } from "rsuite";
import NotificationList from "./NotificationsList";
import NotificationMailer from "./NotificationMailer";
import AutoNotifications from "./AutoNotifications";
import { useHistory, useLocation } from "react-router-dom";

interface INotifications {}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Notifications: FunctionComponent<INotifications> = (props) => {
  enum _view {
    manual = "/admin/notifications/manual",
    auto = "/admin/notifications/auto",
    sended = "/admin/notifications/sended",
  }

  const { pathname } = useLocation();
  const history = useHistory();

  const handleChangeView = (newView: _view) => {
    history.push(newView);
  };

  return (
    <>
      <Nav
        appearance={"default"}
        activeKey={pathname}
        onSelect={handleChangeView}
        style={{ marginBottom: "10px" }}>
        <Nav.Item eventKey={_view.sended}>Skrzynka nadawcza</Nav.Item>
        <Nav.Item eventKey={_view.manual}>Ręczne</Nav.Item>
        <Nav.Item eventKey={_view.auto}>Automatyczne</Nav.Item>
      </Nav>

      {pathname === _view.manual && <NotificationList />}
      {pathname === _view.auto && <AutoNotifications />}
      {pathname === _view.sended && <NotificationMailer />}
    </>
  );
};

export default Notifications;
