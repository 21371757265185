import React, { FunctionComponent, useRef } from "react";
import { IReviewPhotoSimple, IReviewVisit } from "../../../utils/models";
import styles from "./styles.module.scss";

interface IPhotoReviewThumb {
  doReview(
    visitId: string,
    visitQuestionId: string,
    itemId: null | string,
    answerId: Array<string>
  ): void;
  visit: IReviewVisit;
  reviewPhotoSimple: IReviewPhotoSimple;
  hasKPIFeedback: boolean;
  hasSLAFeedback: boolean;
}

export const srcPlaceholder = "/jpeg/placeholder.jpeg";

const PhotoReviewThumb: FunctionComponent<IPhotoReviewThumb> = (props) => {
  if (props.reviewPhotoSimple.photoContentThumbUrl === "")
    props.reviewPhotoSimple.photoContentThumbUrl = null;
  const containerScrollPos = useRef(0);

  const handleOnMouseDown = () => {
    // todo:
    containerScrollPos.current = document.getElementsByClassName(
      `visit-thumbs-container-${props.visit.id}`
    )[0].children[0].children[0].children[0].scrollLeft;
  };

  const handleOnMouseUp = () => {
    // hacky but stable
    const currentScrollLeft = document.getElementsByClassName(
      `visit-thumbs-container-${props.visit.id}`
    )[0].children[0].children[0].children[0].scrollLeft;
    if (containerScrollPos.current === currentScrollLeft) {
      props.doReview(
        props.visit.id,
        props.reviewPhotoSimple.visitQuestionId,
        props.reviewPhotoSimple.itemId,
        [props.reviewPhotoSimple.value.refQuestionAnswerId]
      );
    } else {
      console.log("scroll perform - skip review modal");
    }
  };

  return (
    <div
      className={`${styles.photoReviewThumbWrapper}`}
      onMouseDown={handleOnMouseDown}
      onMouseUp={handleOnMouseUp}>
      <div className={styles.thumbTypeMark}>
        {props.hasKPIFeedback ? (
          <div className={styles.active}>KPI</div>
        ) : (
          <div>KPI</div>
        )}
        {props.hasSLAFeedback ? (
          <div className={styles.active}>SLA</div>
        ) : (
          <div>SLA</div>
        )}
      </div>

      <div className={styles.photoReviewImageWrapper}>
        {/*        <img src={props.reviewPhotoSimple.value.value ?? srcPlaceholder}
             alt={'thumbnail'} />*/}
        <img
          src={props.reviewPhotoSimple.photoContentThumbUrl ?? srcPlaceholder}
          alt={"thumbnail"}
        />
      </div>
    </div>
  );
};

export default PhotoReviewThumb;
