import AxiosHelper, {
  AxiosManager,
  HttpMethod,
  getApiUrl,
} from "./../AxiosHelper";
import { default as axios } from "../AxiosHelper";
import { getAuthHeader, getBaseApiUrl, getOrganizationId } from "../helpers";
import { ICreateQuestion, IGetQuestions } from "../models";

const QuestionsConnection = {
  getQuestions: (data: IGetQuestions) => {
    return axios.post(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/questions/list`,
      data,
      {
        headers: getAuthHeader(),
      }
    );
  },
  createQuestion: (data: ICreateQuestion) => {
    return axios.post(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/questions`,
      data,
      {
        headers: getAuthHeader(),
      }
    );
  },
  updateQuestion: (id: string, data: ICreateQuestion) => {
    return axios.put(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/questions/${id}`,
      data,
      {
        headers: getAuthHeader(),
      }
    );
  },

  getQuestionsFiltersForm: () => {
    return axios.get(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/questions/filter/form`,
      {
        headers: getAuthHeader(),
      }
    );
  },
  getQuestionsFormAdd: () => {
    return axios.get(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/questions/form`,
      {
        headers: getAuthHeader(),
      }
    );
  },
  getQuestionsFormEdit: (id: string) => {
    return axios.get(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/questions/${id}/form`,
      {
        headers: getAuthHeader(),
      }
    );
  },
  batchDelete: (data: Array<{ id: string }>) =>
    AxiosManager.send(
      HttpMethod.deletePost,
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/questions`,
      { questions: data },
      "questions-batch-delete",
      false
    ),
  clone: (questionId: string) => {
    return AxiosHelper.post(getApiUrl(`questions/${questionId}/clone`));
  },
};

export default QuestionsConnection;
