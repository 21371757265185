import React, { useEffect, useState } from "react";
import {
  FCC,
  IAddress,
  ICreateLocation,
  ILocationCustomer,
  ILocationCustomerRegion,
  IOptionsTyped,
  ISelect,
} from "utils/models";
import { createLocationState } from "utils/states";
import { getAddressFullName, getProjectIdFromUrl } from "utils/helpers";
import WhiteCard from "global/atoms/WhiteCard";
import Form from "global/form/Form";
import Input from "global/atoms/Input";
import Textarea from "global/atoms/Textarea";
import Select from "global/atoms/Select";
import ActionsContainer from "global/atoms/ActionsContainer";
import ButtonSubmit from "global/atoms/ButtonSubmit";
import { Col, Row } from "rsuite";
import AddressSelectSection from "./AddressSelectSection";
import Spinner from "../../../../global/atoms/Spinner/Spinner";
import { AxiosPromise } from "axios";
import LocationCustomerForm from "./LocationCustomerForm";
import LocationRegionForm from "./LocationRegionForm";
import { useParams } from "react-router-dom";

interface ILocationEdit {
  handleSubmit: (data: ICreateLocation) => void;
  errors: any; // todo
  dataPromise: (locationId: string) => AxiosPromise<any>;
  isEdit: boolean;
  isAdd?: boolean;
  callbackFormData?: (formData: any) => void;
}

export interface ILocationForm {
  model: {
    name: string;
    recipientAddress: IAddress;
    payerAddress: IAddress;
    address: IAddress;
    locationCustomers: Array<ILocationCustomer>;
    locationRegions: Array<ILocationCustomerRegion>;
  };
  id: string;
  customers: ISelect;
  regions: ISelect;
  disabledElements: string[];
  locationTypes: IOptionsTyped;
  networks: IOptionsTyped;
  projectNetworks: IOptionsTyped;
}

const LocationForm: FCC<ILocationEdit> = (props) => {
  const [data, setData] = useState<ICreateLocation>(createLocationState);
  const [form, setForm] = useState<ILocationForm>();
  const { locationId } = useParams<{ locationId: string }>();

  const isProjectScope = (): boolean => {
    return !!getProjectIdFromUrl();
  };

  const loadForm = (): Promise<any> => {
    return props.dataPromise(locationId).then((_data) => {
      if (props.isEdit) {
        const model = _data.data.model;
        setData({
          ...model,
          networkId: model ? model.network.id : _data.data.networks.value,
          locationTypeId: model
            ? model.locationType.id
            : _data.data.locationTypes.value,
          projectLocationNetworkId: model
            ? model?.projectLocationNetwork?.id
            : _data.data.projectNetworks.value,
          addressId: {
            id: model.address?.id,
            name: getAddressFullName(model.address),
          },
          payerAddressId: {
            id: model.payerAddress?.id,
            name: getAddressFullName(model.payerAddress),
          },
          recipientAddressId: {
            id: model.recipientAddress?.id,
            name: getAddressFullName(model.recipientAddress),
          },
        });
      } else {
        setData({
          ...data,
          networkId: _data.data.networks.value ?? "",
          locationTypeId: _data.data.locationTypes.value ?? "",
          projectLocationNetworkId: _data.data?.projectNetworks?.value ?? "",
        });
      }
      setForm(_data.data);
      if (props.callbackFormData) props.callbackFormData(_data.data);
    });
  };

  useEffect(() => {
    loadForm();
  }, []);

  const isFormDisabled =
    form?.disabledElements?.includes("SAVE_LOCATION_CHANGES") ?? true;
  // const isUserFormDisabled = form?.disabledElements?.includes("SAVE_LOCATION_USERS_CHANGES") ?? true;

  if (form === undefined) return <Spinner />;

  return (
    <>
      <WhiteCard padding={true}>
        <Form
          handleSubmit={(event) => {
            event.preventDefault();
            props.handleSubmit(data);
          }}>
          <Row>
            <Col xs={20}>
              <Input
                type={"text"}
                placeholder={"Nazwa"}
                name={"name"}
                value={data.name}
                state={data}
                disabled={isFormDisabled}
                setState={setData}
                errors={props.errors}
              />
            </Col>
            <Col xs={4}>
              <Input
                placeholder={"NIP"}
                name={"nip"}
                value={data.nip}
                state={data}
                disabled={isFormDisabled}
                setState={setData}
                errors={props.errors}
              />
            </Col>
          </Row>
          <Textarea
            placeholder={"Opis"}
            name={"description"}
            value={data.description}
            state={data}
            disabled={isFormDisabled}
            setState={setData}
            errors={props.errors}
          />
          <Row>
            <Col xs={8}>
              <Select
                placeholder={"Typ lokalizacji"}
                name={"locationTypeId"}
                value={data.locationTypeId}
                options={form.locationTypes.options}
                state={data}
                disabled={isFormDisabled}
                setState={setData}
                errors={props.errors}
              />
            </Col>
            <Col xs={8}>
              <Select
                placeholder={"Sieć (lokalizacji)"}
                name={"networkId"}
                value={data.networkId}
                options={form.networks.options}
                state={data}
                disabled={isFormDisabled}
                setState={setData}
                errors={props.errors}
              />
            </Col>
            {isProjectScope() && (
              <Col xs={8}>
                <Select
                  placeholder={"Sieć projektowa"}
                  name={"projectLocationNetworkId"}
                  value={data.projectLocationNetworkId}
                  options={form.projectNetworks?.options ?? []}
                  state={data}
                  disabled={isFormDisabled}
                  setState={setData}
                  errors={props.errors}
                />
              </Col>
            )}
          </Row>
          <Row>
            <Col xs={8}>
              <Input
                type={"text"}
                placeholder={"Telefon"}
                name={"phone"}
                value={data.phone}
                state={data}
                disabled={isFormDisabled}
                setState={setData}
                errors={props.errors}
              />
            </Col>
            <Col xs={8}>
              <Input
                type={"text"}
                placeholder={"Zewnętrzny kod lokalizacji"}
                name={"code"}
                value={data.code}
                state={data}
                disabled={isFormDisabled}
                setState={setData}
                errors={props.errors}
              />
            </Col>
            <Col xs={8}>
              <Input
                type={"text"}
                placeholder={"Kod sieci dla lokalizacji"}
                name={"networkLocationCode"}
                value={data.networkLocationCode}
                state={data}
                disabled={isFormDisabled}
                setState={setData}
                errors={props.errors}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={8}>
              <Input
                type={"text"}
                placeholder={"Email (adresy rozdzielone średnikiem)"}
                name={"email"}
                value={data.email}
                state={data}
                disabled={isFormDisabled}
                setState={setData}
                errors={props.errors}
              />
            </Col>
            <Col xs={8}>
              <Input
                type={"text"}
                placeholder={"Osoba do kontaktu"}
                name={"contactPerson"}
                value={data.contactPerson}
                state={data}
                disabled={isFormDisabled}
                setState={setData}
                errors={props.errors}
              />
            </Col>
            <Col xs={8}>
              {data?.id && (
                <Input
                  disabled={true}
                  type={"text"}
                  placeholder={"Numer porządkowy"}
                  name={"incrementId"}
                  value={data.incrementId}
                  state={data}
                  setState={setData}
                  errors={props.errors}
                />
              )}
            </Col>
          </Row>
          <AddressSelectSection
            errors={props.errors}
            setData={setData}
            data={data}
            disabled={isFormDisabled}
            form={form}
          />
          <LocationCustomerForm
            setLocationState={setData}
            locationState={data}
            disabled={isFormDisabled}
            locationForm={form}
          />
          <LocationRegionForm
            setLocationState={setData}
            locationState={data}
            disabled={isFormDisabled}
            locationForm={form}
          />
          {props.children && props.children}
          {(props.isEdit || props.isAdd) && (
            <ActionsContainer>
              <ButtonSubmit form={form} />
            </ActionsContainer>
          )}
        </Form>
      </WhiteCard>
    </>
  );
};

export default LocationForm;
