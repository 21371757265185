import React, { CSSProperties, memo, useContext } from "react";
import { FCC } from "utils/models";
import {
  IVisitEditErrorContext,
  VisitEditErrorContext,
} from "../../VisitEditContextWrapper";

interface IQuestionErrors {
  refQuestionId: string;
}

const QuestionErrors: FCC<IQuestionErrors> = (props) => {
  const { errors } = useContext(
    VisitEditErrorContext
  ) as IVisitEditErrorContext;

  const messages = errors?.[props.refQuestionId];

  if (!messages?.length) {
    return <></>;
  }

  const styles: CSSProperties = {
    background: "#fff2f2",
    borderRadius: "7px",
    marginTop: "5px",
    marginBottom: "3px",
    padding: "5px",
    fontSize: "12px",
    color: "var(--rs-form-errormessage-text)",
  };

  return (
    <div style={styles}>
      {messages.map((msg, i) => (
        <div key={`err-${props.refQuestionId}-${i}`}>{msg}</div>
      )) ?? ""}
    </div>
  );
};

export default memo(QuestionErrors);
