import React, { FunctionComponent, SyntheticEvent } from "react";
import styles from "./atoms.module.scss";
import CSS from "csstype";
import { findErrorByName } from "../../utils/formErrorsHelper";
import { Form, InputNumber, Input as RInput } from "rsuite";

interface IInput {
  style?: CSS.Properties;
  type?: InputTypes | keyof typeof InputTypes;
  placeholder: string;
  name: string;
  onChange?: (event: any, value: string | number) => void;
  value: any;
  disabled?: boolean;
  setState?: any;
  state?: any;
  errors?: any;
  max?: number;
  debugData?: any;
  id?: any;
  pattern?: string;
  customError?: string;
  allowNegativeNumbers?: boolean;
  hint?: string;
  min?: number;
  autocomplete?: string;
}

export enum InputTypes {
  decimal = "decimal",
  text = "text",
  number = "number",
  password = "password",
}

const Input: FunctionComponent<IInput> = (props) => {
  const type = props.type === undefined ? "text" : props.type;
  const allowNegativeNumbers =
    props.allowNegativeNumbers === undefined
      ? true
      : props.allowNegativeNumbers;

  const handleChange = (value: string | number) => {
    if (typeof value === "number") {
      if (!allowNegativeNumbers) {
        value = value < 0 ? 0 : value;
      }

      if (type === "decimal") {
        value = Number(value).toFixed(2);
        // @ts-ignore
        value = value < 0 ? "0.00" : value;
      }
    }

    props.setState((s) => ({ ...s, [props.name]: value }));
  };

  let Element: typeof RInput | typeof InputNumber;
  switch (props.type) {
    case InputTypes.decimal:
    case InputTypes.number:
      Element = InputNumber;
      break;
    case InputTypes.password:
    case InputTypes.text:
      Element = RInput;
      break;
    default:
      Element = RInput;
      break;
  }

  return (
    <Form.Group>
      {props.placeholder && (
        <Form.ControlLabel>{props.placeholder} </Form.ControlLabel>
      )}
      <Element
        type={
          props.type === InputTypes.password ? InputTypes.password : undefined
        }
        id={props.id}
        value={props.value}
        style={props.style ?? { width: "100%" }}
        name={props.name}
        max={props.max}
        min={props.min}
        onChange={(value: string | number, event: SyntheticEvent) => {
          if (props.onChange) props.onChange(event, value);
          else handleChange(value);
        }}
        disabled={props.disabled}
        autoComplete={props.autocomplete}
        placeholder={" "}
      />
      {props.hint && (
        <Form.HelpText style={{ padding: 0 }}>{props.hint}</Form.HelpText>
      )}
      {props.customError && (
        <span className={styles.errors}>{props.customError}</span>
      )}
      {props.errors && (
        <span className={styles.errors}>
          {findErrorByName(props.name, props.errors)}
        </span>
      )}
    </Form.Group>
  );
};

export default Input;
