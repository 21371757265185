import React, { FunctionComponent, useEffect } from "react";
import { Panel, SelectPicker, TagPicker } from "rsuite";
import { IAlertEntityOption, IAlertEntityStore, SetState } from "utils/models";

interface IAlertFormConfig {
  state: IAlertEntityStore;
  setState: SetState<IAlertEntityStore>;
  configOptions: Array<IAlertEntityOption>;
  isEdit: boolean;
  readOnly: boolean;
}

const AlertFormConfig: FunctionComponent<IAlertFormConfig> = (props) => {
  useEffect(() => {
    // apply default value to the state
    if (!props.isEdit) {
      props.setState((s) => ({
        ...s,
        configOptions: props.configOptions.reduce((acc, option) => {
          if (option.defaultValue && option.defaultValue.length > 0) {
            acc[option.id] = option.defaultValue;
          }
          return acc;
        }, {}),
      }));
    }
  }, []);

  const getConfigValue = (configOptionId: string) => {
    const stateConfig = props.state.configOptions?.[configOptionId];
    const config = props.configOptions.find((co) => co.id === configOptionId);

    if (stateConfig) {
      switch (config.type) {
        case "multiselect":
          return stateConfig;
        case "select":
          return stateConfig[0];
      }
    }

    return undefined;
  };

  const configValueOnChange = (configOptionId: string, value: any) => {
    const config = props.configOptions.find((co) => co.id === configOptionId);
    let parsedValue = value;

    switch (config.type) {
      case "multiselect":
        parsedValue = value;
        break;
      case "select":
        parsedValue = [value];
        break;
    }

    props.setState((s) => ({
      ...s,
      configOptions: {
        ...s.configOptions,
        [configOptionId]: parsedValue,
      },
    }));
  };

  return (
    <Panel shaded header={"Konfiguracja"}>
      {props.configOptions.map((opt) => {
        let formElm = <></>;
        switch (opt.type) {
          case "multiselect":
            formElm = (
              <TagPicker
                block
                disabled={props.readOnly}
                placeholder={"brak"}
                data={opt.options}
                value={getConfigValue(opt.id)}
                onChange={(values) => {
                  props.setState((s) => ({
                    ...s,
                    configOptions: {
                      ...s.configOptions,
                      [opt.id]: values,
                    },
                  }));
                }}
              />
            );
            break;
          case "select":
            formElm = (
              <SelectPicker
                block
                disabled={props.readOnly}
                placeholder={"brak"}
                data={opt.options}
                value={getConfigValue(opt.id)}
                onChange={(value) => configValueOnChange(opt.id, value)}
              />
            );
            break;
        }

        return (
          <div key={`opt_${opt.id}`} style={{ marginBottom: "10px" }}>
            {opt.label}
            {formElm}
          </div>
        );
      })}
    </Panel>
  );
};

export default AlertFormConfig;
