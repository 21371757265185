import axios, {
  AxiosRequestConfig,
  AxiosTransformer,
  Canceler,
  AxiosInstance,
  AxiosPromise,
  AxiosResponse,
} from "axios";
import dayjs from "dayjs";
import {
  getAuthHeader,
  getBaseApiUrl,
  getOrganizationId,
  getTokenFromLocalStorage,
  getUrlAuthToken,
  handleErrors,
} from "./helpers";
import { dispatchDisabledElements } from "redux/store";

let cancelPreviousReq: Array<{ cancelTag: string; cancel: Canceler }> = [];

export const getCustomConfig = (): AxiosRequestConfig => {
  return {
    headers: getAuthHeader(),
    baseURL: getBaseApiUrl(),
    responseType: "json",
  };
};

const cancelTokenApply = (
  cancelTag: string | undefined,
  configCustom: AxiosRequestConfig
): AxiosRequestConfig => {
  if (cancelTag) {
    if (
      cancelPreviousReq.length &&
      cancelPreviousReq.find((p) => p.cancelTag === cancelTag) !== undefined
    ) {
      cancelPreviousReq.find((p) => p.cancelTag === cancelTag)?.cancel();
      cancelPreviousReq = cancelPreviousReq.filter(
        (p) => p.cancelTag !== cancelTag
      );
    }
    configCustom.cancelToken = new axios.CancelToken(function executor(c) {
      cancelPreviousReq.push({ cancelTag: cancelTag, cancel: c });
    });
  }
  return configCustom;
};

const dateTransformer: AxiosTransformer = (d) => {
  if (d instanceof FormData) {
    // skip
    console.log("dateTransformer - FormData - skip");
    return d;
  }

  if (d instanceof Date) {
    // todo: timezone date parser control
    // return data.toLocaleString()
    // return data.toISOString()

    // local to fake UTC transition

    let date: string | number = d.getDate();
    if (date < 10) date = `0${date}`;

    let month: string | number = d.getMonth() + 1;
    if (month < 10) month = `0${month}`;

    let hours: string | number = d.getHours();
    if (hours < 10) hours = `0${hours}`;

    let minutes: string | number = d.getMinutes();
    if (minutes < 10) minutes = `0${minutes}`;
    return `${d.getFullYear()}-${month}-${date}T${hours}:${minutes}:00.000Z`;
  }

  // [date]
  if (Array.isArray(d)) {
    return d.map((val) => dateTransformer(val));
  }

  // {key: date}
  if (typeof d === "object" && d !== null) {
    return Object.fromEntries(
      Object.entries(d).map(([key, val]) => [key, dateTransformer(val)])
    );
  }

  return d;
};

const responseParser: AxiosTransformer = (d) => {
  if (Array.isArray(d)) {
    return d.map((val) => responseParser(val));
  }
  if (typeof d === "object" && d !== null) {
    return Object.fromEntries(
      Object.entries(d).map(([key, val]) => [key, responseParser(val)])
    );
  }
  // todo: why i get here entire object as string out of the sudden
  if (typeof d === "string") {
    try {
      const date = dayjs(d);
      if (date.isValid() && d.split("-").length >= 2) {
        return date.toDate();
      }
    } catch (e) {
      // nothign
    }
  }
  return d;
};

const axiosInstance = axios.create({
  transformRequest: [dateTransformer].concat(
    axios.defaults.transformRequest ?? []
  ),
});

export const getApiUrl = (path: string) =>
  `${getBaseApiUrl()}/organizations/${getOrganizationId()}/${path}`;

export enum HttpMethod {
  get = "get",
  getBlob = "getBlob",
  delete = "delete",
  deletePost = "deletePost",
  post = "post",
  put = "put",
  postBlob = "getPost",
  patch = "patch",
}

export class AxiosManager {
  parseResponseDate: boolean;
  config: AxiosRequestConfig;
  instance: AxiosInstance;
  token: string;
  AC: AbortController;
  static selfInstance: AxiosManager | null = null;

  constructor(parseResponseDate = true, config?: AxiosRequestConfig) {
    this.parseResponseDate = parseResponseDate;
    this.config = config ?? this._defaultConfig();
    this.token = getTokenFromLocalStorage() ?? "";
    this.instance = axios.create(this.config);
    this.AC = new AbortController();
  }

  static abort() {
    if (AxiosManager.selfInstance) {
      AxiosManager.selfInstance.AC.abort();
      AxiosManager.selfInstance.AC = new AbortController();
    }
  }

  static getSelfInstance() {
    if (
      !AxiosManager.selfInstance ||
      getTokenFromLocalStorage() !== AxiosManager.selfInstance.token
    ) {
      AxiosManager.selfInstance = new AxiosManager();
    }
    return AxiosManager.selfInstance;
  }

  static send<T = any>(
    method: HttpMethod,
    url: string,
    data?: any,
    cancelTag?: string
  ): Promise<AxiosResponse<T>> {
    const m = AxiosManager.getSelfInstance();
    m.config.signal = AxiosHelper.AC.signal;

    if (cancelTag) {
      cancelTokenApply(cancelTag, m.config);
      m.instance = axios.create(m.config);
    }

    let request: AxiosPromise<T>;

    switch (method) {
      case HttpMethod.get:
        request = m.instance.get<T>(url, m.config);
        break;
      case HttpMethod.getBlob: //fixme:
        m.config.responseType = "blob";
        request = m.instance.get<T>(url, m.config);
        break;
      case HttpMethod.post:
        request = m.instance.post<T>(url, data, m.config);
        break;
      case HttpMethod.put:
        request = m.instance.put<T>(url, data, m.config);
        break;
      case HttpMethod.delete:
        request = m.instance.delete<T>(url, m.config);
        break;
      case HttpMethod.deletePost:
        m.config.data = data;
        request = m.instance.delete(url, m.config);
        break;
      case HttpMethod.postBlob: //fixme:
        m.config.responseType = "blob";
        request = m.instance.post<T>(url, data, m.config);
        break;
      case HttpMethod.patch:
        request = m.instance.patch<T>(url, data, m.config);
        break;
      default:
        throw "Unsupported method!";
    }

    return request
      .then((result) => {
        // @ts-ignore
        if (result.data?.disabledElements) {
          // @ts-ignore
          dispatchDisabledElements(result.data?.disabledElements);
        }
        return result;
      })
      .catch((err) => {
        return handleErrors(err);
      });
  }

  _defaultConfig(): AxiosRequestConfig {
    const _config: AxiosRequestConfig = {
      headers: getAuthHeader(),
      baseURL: getBaseApiUrl(),
      responseType: "json",
    };

    if (getUrlAuthToken()) {
      _config.headers = getAuthHeader(getUrlAuthToken());
    }

    // axios.interceptors.request.use(dateTransformer)
    _config.transformRequest = [dateTransformer].concat(
      axios.defaults.transformRequest ?? []
    );

    if (this.parseResponseDate) {
      // axios.interceptors.response.use(responseParser)
      _config.transformResponse = [responseParser].concat(
        axios.defaults.transformResponse ?? []
      );
    }

    return _config;
  }
}

// todo: refactor AxiosManager
const AxiosHelper = {
  AC: new AbortController(),
  abort: () => {
    AxiosHelper.AC.abort();
    AxiosHelper.AC = new AbortController();
  },
  _config: (
    config: AxiosRequestConfig | undefined,
    cancelTag?: string
  ): AxiosRequestConfig => {
    const _config: AxiosRequestConfig =
      config === undefined ? getCustomConfig() : config;
    if (getUrlAuthToken()) {
      _config.headers = getAuthHeader(getUrlAuthToken());
    }
    if (cancelTag) cancelTokenApply(cancelTag, _config);

    // const ab = new AbortController()
    // AxiosHelper.abortControllers.push({ controller: ab, time: Date.now(), aborted: false })
    _config.signal = AxiosHelper.AC.signal;
    return _config;
  },

  get: (url: string, config?: AxiosRequestConfig, cancelTag?: string) =>
    axiosInstance
      .get(url, AxiosHelper._config(config, cancelTag))
      .catch(handleErrors),

  getBlob: (url: string, config?: AxiosRequestConfig, cancelTag?: string) => {
    const configCustom = AxiosHelper._config(config, cancelTag);
    configCustom.responseType = "blob";
    return axiosInstance.get(url, configCustom).catch(handleErrors);
  },
  getBlobPost: (
    url: string,
    data?: any,
    config?: AxiosRequestConfig,
    cancelTag?: string
  ) => {
    const configCustom = AxiosHelper._config(config, cancelTag);
    configCustom.responseType = "blob";
    return axiosInstance.post(url, data, configCustom).catch(handleErrors);
  },
  delete: (url: string, config?: AxiosRequestConfig) =>
    axiosInstance.delete(url, AxiosHelper._config(config)).catch(handleErrors),

  batchDelete: (url: string, data: any, config?: AxiosRequestConfig) => {
    const configCustom = AxiosHelper._config(config);
    configCustom.data = data;
    return axiosInstance.delete(url, configCustom).catch(handleErrors);
  },
  post: (
    url: string,
    data?: any,
    config?: AxiosRequestConfig,
    cancelTag?: string
  ) => {
    return axiosInstance
      .post(url, data, AxiosHelper._config(config, cancelTag))
      .catch(handleErrors);
  },
  put: (
    url: string,
    data?: any,
    config?: AxiosRequestConfig,
    cancelTag?: string
  ) => {
    return axiosInstance
      .put(url, data, AxiosHelper._config(config, cancelTag))
      .catch(handleErrors);
  },
  patch: (
    url: string,
    data?: any,
    config?: AxiosRequestConfig,
    cancelTag?: string
  ) => {
    return axiosInstance
      .patch(url, data, AxiosHelper._config(config, cancelTag))
      .catch(handleErrors);
  },
};

export default AxiosHelper;
