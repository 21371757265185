import React, { createContext, FunctionComponent, useContext } from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { DraggableSyntheticListeners } from "@dnd-kit/core";
import { FCC } from "utils/models";

interface Context {
  attributes: Record<string, any>;
  listeners: DraggableSyntheticListeners;
  setActivatorNodeRef?: (node: HTMLElement | null) => void;
}

export const SortableItemContext = createContext<Context>({
  attributes: {},
  listeners: undefined,
  setActivatorNodeRef: undefined,
});

const SortableItem: FCC<{
  id: string;
  dragHandleActivator: boolean;
}> = (props) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
  } = useSortable({ id: props.id });

  const context: Context = {
    attributes: attributes,
    listeners: listeners,
    setActivatorNodeRef: setActivatorNodeRef,
  };

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <SortableItemContext.Provider value={context}>
      {!props.dragHandleActivator ? (
        <div ref={setNodeRef} style={style} {...listeners}>
          {props.children}
        </div>
      ) : (
        <div ref={setNodeRef} style={style}>
          {props.children}
        </div>
      )}
    </SortableItemContext.Provider>
  );
};

export const DragHandle: FunctionComponent = () => {
  const { attributes, listeners, setActivatorNodeRef } =
    useContext(SortableItemContext);

  return (
    <button
      type="button"
      className="DragHandle"
      {...attributes}
      {...listeners}
      ref={setActivatorNodeRef}>
      <svg viewBox="0 0 20 20" width="12">
        <path d="M7 2a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 2zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 8zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 14zm6-8a2 2 0 1 0-.001-4.001A2 2 0 0 0 13 6zm0 2a2 2 0 1 0 .001 4.001A2 2 0 0 0 13 8zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 13 14z"></path>
      </svg>
    </button>
  );
};

export default SortableItem;
