import { closestCenter, DndContext, DragEndEvent } from "@dnd-kit/core";
import {
  restrictToVerticalAxis,
  restrictToWindowEdges,
} from "@dnd-kit/modifiers";
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import _ from "lodash";
import React from "react";
import SortableItem from "../SortableItem";
import { FCC } from "utils/models";

interface ISortableList {
  onSortEnd: (sortedData: Array<any>) => void;
  data: Array<any>;
  idKeyName: string;
  mapFunction: (item: any, itemKey: number) => JSX.Element;
  dragHandleActivator?: boolean;
  disabled?: boolean;
}

const SortableList: FCC<ISortableList> = (props) => {
  const ids = props.data.map((d) => d[props.idKeyName]);

  const onSortEnd = (event: DragEndEvent) => {
    const active = event.active.id;
    const over = event.over?.id ?? 0;

    if (active !== over && props.data !== null) {
      const oldIndex = props.data.findIndex(
        (v) => v[props.idKeyName] === active
      );
      const newIndex = props.data.findIndex((v) => v[props.idKeyName] === over);
      props.onSortEnd(arrayMove(_.cloneDeep(props.data), oldIndex, newIndex));
    }
  };

  return (
    <DndContext
      collisionDetection={closestCenter}
      modifiers={[restrictToVerticalAxis, restrictToWindowEdges]}
      onDragEnd={onSortEnd}>
      <SortableContext
        items={ids}
        disabled={props.disabled ?? false}
        strategy={verticalListSortingStrategy}>
        {props.data.map((item, itemKey) => (
          <SortableItem
            id={item[props.idKeyName]}
            dragHandleActivator={props.dragHandleActivator ?? false}
            key={`si-${item[props.idKeyName]}`}>
            {props.mapFunction(item, itemKey)}
          </SortableItem>
        ))}
      </SortableContext>
    </DndContext>
  );
};

export default SortableList;
